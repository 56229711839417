import {Component, ElementRef, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {EventDistributerService} from '../../../event-distributer.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {

  baseUrlGuidance = 'https://gointeract.io/interact/index?interaction=15cdc7ec5548-94b9d48e45fdd67e-1371&accountId=democenter_df&loadFrom=CDN&appkey=dcab776b-0045-4ce8-b759-f55ed07d8875&Environment-Name=Dev&customerMobileNumber=%2B4915112136270&ani=+4915112136270&dnis=+4932211001857&conversationId=';

  iFrames = new Array<IHostedIFrame>();

  guidanceId = 0;

  constructor(
    private domSanitizer: DomSanitizer,
    private events: EventDistributerService,
    private hostElement: ElementRef,
  ) {

  }

  ngOnInit(): void {

    this.events.listen().subscribe((event) => {

      console.log('Main event listener', event);
      if (event.name === 'IncomingCall') {
        this.showGuidance(event.data.serviceCodeData);
      } else if(event.name === 'AddGuidance') {
        this.showGuidance(event.data.serviceCodeData);
      } else if (event.name === 'agentAssistantInitialized') {
        console.log('Show guidance with conversation sessionId: ', event.data.sessionId);
        this.showGuidance(event.data.sessionId);
      } else if (event.name === 'showKnowledgeArticle') {

        const iframe = {
          id: 'knowledge',
          url: event.url,
          name: 'Knowledge',
          visible: true
        };

        let found = false;

        this.iFrames.map((frame: any) => {
          if (frame.id === 'knowledge') {
            found = true;
            frame.url = event.url;
          }
        });

        if (!found) {
          this.iFrames.push(iframe);
        }

      } else if (event.name === 'AddAutomation') {
        let automationFrame;

        this.iFrames.map((frame: any) => {
          if (frame.id === event.id) {
            automationFrame = frame;
          }
        });

        if (!automationFrame) {
          automationFrame = {
            url: event.url,
            id: event.id,
            name: event.header,
            visible: true
          };

          this.iFrames.push(automationFrame);

        }
        this.showFrame(automationFrame);
      }
    });
  }

  showGuidance(serviceCode: any): void {

    let frameConfig;

    if (serviceCode.agentUrl) {

      serviceCode.agentUrl = serviceCode.agentUrl;

      frameConfig = {
        id: 'guidance' + this.guidanceId,
        url: serviceCode.agentUrl + '&conversationId=' + serviceCode.conversationId + '&spui=' + serviceCode.spui,
        name: 'Guidance',
        visible: true
      };

    } else {
      frameConfig = {
        id: 'guidance' + this.guidanceId,
        url: this.baseUrlGuidance + serviceCode.conversationId + '&spui=' + serviceCode.spui,
        name: 'Guidance',
        visible: true
      };
    }
    this.guidanceId++;
    this.iFrames.push(frameConfig);

    this.showFrame(frameConfig);
    //this.removeScript();
  }

  removeScript() {

    let active;

    this.iFrames.map(frame => {
      if (frame.visible) {
        active = frame;
      }
    });

    if (active) {
      this.iFrames = _.without(this.iFrames, active);

      if (this.iFrames.length > 0) {
        this.iFrames[0].visible = true;
      }

    }

  }

  showFrame(frame: IHostedIFrame) {
    this.iFrames.map((iframe) => {
      iframe.visible = iframe.id === frame.id;
    });
  }





}
