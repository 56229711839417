import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WatsonDiscoveryService {

  private queryUrl: string;

  constructor(
    private http: HttpClient,
  ) {

  }

  query(text: string): Promise<any> {

    return new Promise((resolve, reject) => {

     this.http.get('https://utilities1.gointeract.io/interact_ai/api/WatsonDiscoveries/query', {
       params: {
         //environmentId: '8224988f-044f-4817-944f-ca62c3c311cd',
         //collectionId: 'fe9ba82e-a30d-4f7e-8706-76235c2f07e5',
         //username: '13b95494-0632-490d-aa32-1230ad5376e6',
         //password: 'l5qrTVbzgcI0',

         environmentId: '5dcb3ac1-23c7-4c22-92f7-75fc8325041f',
         collectionId: '4a5f19c5-7423-4030-a059-147e2ea725b8',
         apiToken: 'bwW4Xd2lMpwcSdJCuey-U3WCLW_Au997wF5M4IiyF5OS',
         url: 'https://gateway.watsonplatform.net/discovery/api',
         queryText: text
       }
     }).subscribe((next: any) => {
       resolve(next);
     }, (err) => {
       reject(err);
     });

    });

  }


}
